import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import { signInWithGoogle } from "../../util/firebase";
import googlelogo from "../../images/btn_google_signin_light_normal_web@2x.png";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (currentUser) {
      history.push("/user");
    }
  }, [currentUser, history]);

  async function handleSubmit(e) {
    e.preventDefault();
    
    try {
      setError("");
      setLoading(true);
      
      // Add try-catch around login
      await login(emailRef.current.value, passwordRef.current.value).catch(error => {
        console.error('Login failed:', error);
        setError(error.message || 'Failed to log in');
        throw error;
      });
      
      history.push("/user");
    } catch (error) {
      console.error('Error caught:', error);
      setError("Invalid email or password");
    }
    setLoading(false);
  }

  return (
    <>
      <Card style={{ display: "flex", flexWrap: "wrap", minWidth: "700px" }}>
        <Card.Body>
          <h2 className="text-center mb-4">Log In</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              {loading ? "Logging in..." : "Log In"}
            </Button>
          </Form>
          <div className="w-100 text-center mt-3">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        Need an account? <Link to="/signup">Sign Up</Link>
      </div>
      <hr />
      <br />
      <div>
        <button onClick={signInWithGoogle}>
          <img src={googlelogo} alt="Sign in with Google" />
        </button>
      </div>
    </>
  );
}