import React from "react";
import { Link } from 'react-router-dom';


export const About = () => {
  return (
    <>

      <hr />
      <article>
      <h1>Rundoit tool</h1>
  <p>A unique new productivity tool that helps you tackle your to-do list in a unique and efficient way. Our app randomly selects a task from your to-do list and starts a timer for that task, so you can focus on completing it without getting bogged down by decision making. By randomly selecting a task, you'll be able to break through procrastination and tackle tasks that you might have been avoiding.</p>
  <p>This app also allows you to set a time limit for your task, so you can be sure that you're not spending too much time on any one task. Additionally, you can also create multiple to-do lists for different projects, so you can stay organized and on-task.</p>
  <p>Our tool is designed to help you maximize your productivity and achieve your goals faster, by providing you with a new way to manage your to-do list and time. <Link to='./general'>Go to Tools</Link> and give it a try.</p>
      </article>
      <article>
      <h1>Health tool</h1>
      <h2>Coming soon!</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
      </article>
      <article>
      <h1>Progress tool</h1>
      <h2>Coming soon!</h2>

      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
      </article>
    </>
  );
};
 
