
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

export const Rundoit = () => {
    return (
      <>
        <hr />
        {/* <body class="bg-gray-100"> */}

<div class="ud-container ud-mx-auto ud-py-8">

    {/* <!-- Introduction --> */}
    <div class="ud-p-6 ud-my-4 ud-bg-white ud-rounded-lg ud-shadow-md mb-8">
        <h1 class="ud-text-3xl ud-font-bold mb-4">A Productivity Tool</h1>
        <p class="ud-text-gray-700">Rundoit is an innovative productivity tool designed to combat procrastination and maximize efficiency. At its core, Rundoit leverages Parkinson's Law, which suggests that work expands to fill the time available. By introducing randomness into task selection and timing, Rundoit simplifies decision-making and minimizes the energy required to choose a task.</p>
    </div>

    {/* <!-- Key Features --> */}
    <div class="ud-p-6 ud-bg-white ud-rounded-lg ud-shadow-md mb-8">
     
        <ul class="ud-list-disc ud-list-inside ud-text-gray-700">
            <li>Parkinson's Law Integration: Rundoit harnesses Parkinson's Law by randomizing both task selection and timing. This ensures that users focus on completing tasks rather than deliberating over what to do next.</li>
            <li>Random Timer: To encourage immediate action, Rundoit initiates a random timer if users fail to select a task promptly. This feature adds a sense of urgency, nudging users to start working without delay.</li>
            <li>Flexible Time Options: Users have the flexibility to choose between randomized time intervals or set predefined Pomodoro sessions (e.g., 25 minutes, 45 minutes, or 1 hour). This customization caters to individual preferences and work styles.</li>
            <li>Simple Interface: Rundoit offers a user-friendly interface for seamless navigation. Users can effortlessly add their favorite activities or create a to-do list, making it easy to integrate Rundoit into their daily workflow.</li>
        </ul>
    </div>

 
</div>
{/* 
</body> */}
        <section>
        <div className="call-to-action">
          <div>
            Sign up to create a FREE acount to customise and interact with the tools and make ulimited
            changes
            <br></br>
          </div>

          <div>
            <Button variant="contained" color="primary" size="large">
              {" "}
              <Link
                to="./signup"
                style={{ textDecoration: "none", color: "white" }}
              >
                Sign Up
              </Link>
            </Button>
          </div>
        </div>
      </section>
      </>
    );
  };
  