import React from "react";

import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
export const Blog = () => {
  return (
    <>
     
        <hr />
        
<div class="ud-container ud-mx-auto ud-py-8">
        {/* <!-- How to Use Rundoit --> */}
        <div class="ud-p-6 ud-my-4 ud-bg-white ud-rounded-lg ud-shadow-md ud-mb-8">
        <h2 class="ud-text-2xl ud-font-bold mb-4">How to Use it:</h2>
        <ol class="ud-list-decimal list-inside text-gray-700">
            <li>Add Tasks: Start by adding your favorite activities or creating a to-do list within the app.</li>
            <li>Task Association: Optionally, associate default tasks with specific activities you have in mind. For example, designate "Task 1" for writing an email and "Task 2" for fixing a device.</li>
            <li>Initiate Timer: Press the "Random" button to begin the timer. Rundoit will randomly select a task to start, fostering a dynamic and efficient workflow. Alternatively, access the settings to set a specific timer duration if preferred.</li>
        </ol>
    </div>

    {/* <!-- Conclusion --> */}
    <div class="ud-p-6 bg-white ud-rounded-lg ud-shadow-md ud-mb-8">
        <p class="ud-text-gray-700 ud-font-bold">With this, you can overcome procrastination, streamline your workflow, and accomplish more in less time. Embrace the power of randomness and productivity with this tool today!</p>
    </div>

    {/* <!-- Random Mode --> */}
    <div class="ud-p-6 ud-bg-white ud-rounded-lg ud-shadow-md mb-8">
        <h2 class="ud-text-2xl ud-font-bold ud-mb-4">Random Mode:</h2>
        <p class="ud-text-gray-700">In Random Mode, Rundoit operates at its most spontaneous, randomly selecting tasks and durations to inject an element of surprise and urgency into your workflow. This mode is perfect for those seeking an unpredictable yet dynamic approach to task management.</p>
    </div>

    {/* <!-- Flow Mode --> */}
    <div class="ud-p-6 ud-bg-white ud-rounded-lg ud-shadow-md mb-8">
        <h2 class="ud-text-2xl ud-font-bold ud-mb-4">Flow Mode:</h2>
        <p class="ud-text-gray-700">Flow Mode is tailored for users who thrive on uninterrupted concentration and deep work sessions. By minimizing interruptions and distractions, Rundoit enables you to enter a state of flow where productivity and creativity flourish. This mode is ideal for tackling complex tasks or creative projects that demand sustained focus.</p>
    </div>

    {/* <!-- Start Here Mode --> */}
    <div class="ud-p-6 ud-bg-white ud-rounded-lg ud-shadow-md mb-8">
        <h2 class="ud-text-2xl ud-font-bold ud-mb-4">Start Mode</h2>
        <p class="ud-text-gray-700">Start Here mode provides a structured starting point for users who prefer a more organized approach to task management. By guiding you through a predefined sequence of tasks, Rundoit helps you kickstart your productivity journey and stay on track towards achieving your goals. Whether you're a seasoned planner or just getting started, Start Here mode offers a roadmap for success.</p>
    </div>

    {/* <!-- Refresh Feature --> */}
    <div class="ud-p-6 ud-bg-white ud-rounded-lg ud-shadow-md mb-8">
        <h2 class="ud-text-2xl ud-font-bold ud-mb-4">Refresh:</h2>
        <p class="ud-text-gray-700">Additionally, Rundoit's Refresh feature allows you to reset and rejuvenate your workflow, providing a quick way to clear your mind and refocus on your priorities. With just a click, you can refresh your task list and approach each new challenge with renewed energy and clarity.</p>
    </div>

</div>

        <section>
        <div className="call-to-action">
          <div>
            Sign up to create a FREE account to customize and interact with the tools and make unlimited
            changes
            <br></br>
          </div>

          <div>
            <Button variant="contained" color="primary" size="large">
              {" "}
              <Link
                to="/signup"
                style={{ textDecoration: "none", color: "white" }}
              >
                Sign Up
              </Link>
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};
