import React, { useState, useEffect } from 'react';

import styled from "styled-components";
import { ref, auth, db, onAuthStateChanged, onValue, remove } from '../../util/firebase';

import DeleteIcon from "@material-ui/icons/Delete";
import Countdown from "../CountDown";
import TimerUp from "../TimerUP";
import Form from "./Form";
import "../../App.css";


const DeleteButton = styled.div`
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
  transition: all 0.2s;

  &:hover {
    background-color: #ffcccc;
  }
`;
const InputContainer = styled.div`
  margin-bottom: 2rem;
`;
const TodoList = ({ onSubmit }) => {
  const [todoList, setTodoList] = useState({});
  const [showTime, setShowTime] = useState(false);
  const [rando, setRando] = useState("");
  const [mypick, setMyPick] = useState(25);
  const [clock, setClock] = useState(false);

  useEffect(() => {
    let unsubscriber;

    const unsubAuth = onAuthStateChanged(auth, (user) => {
      if (!user) return;

      const todoListRef = ref(db, `users/${user.uid}/todoList`);
      const unsubTodo = onValue(todoListRef, (snapshot) => {
        setTodoList(snapshot.val() || {});
      });

      return () => unsubTodo();
    });

    return () => {
      unsubAuth();
      if (unsubscriber) unsubscriber();
    };
  }, []);

  const deleteTodo = (id) => {
    const dbRef = ref(db, `users/${auth.currentUser.uid}/todoList/${id}`);
    remove(dbRef)
      .then(() => {
        const updatedTodos = { ...todoList };
        delete updatedTodos[id];
        setTodoList(updatedTodos);
      })
      .catch((error) => {
        console.error("Error deleting todo:", error);
      });
  };

  const fetchTodoList = () => {
    const user = auth.currentUser;
    if (!user) return;

    const todoListRef = ref(db, `users/${user.uid}/todoList`);
    onValue(todoListRef, (snapshot) => {
      setTodoList(snapshot.val() || {});
    });
  };

  const randomTodo = () => {
    const todoData = Object.values(todoList);
    if (!todoData.length) return;

    const selectedTodo = todoData[Math.floor(Math.random() * todoData.length)];
    setRando(selectedTodo.title);

    const updatedTodos = todoData.filter(item => item.id !== selectedTodo.id);
    setTodoList(updatedTodos.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}));

    setTimeout(() => setClock(true), 2000);

    let maximum = 450;
    let minimum = 250;
    const randomnumber = Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;

    setMyPick(randomnumber);
  };

  const startTodo = () => {
    const todoData = Object.values(todoList);
    if (!todoData.length) return;

    const selectedTodo = todoData[Math.floor(Math.random() * todoData.length)];
    setRando(selectedTodo.title);

    const updatedTodos = todoData.filter(item => item.id !== selectedTodo.id);
    setTodoList(updatedTodos.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}));

    setTimeout(() => setClock(true), 2000);
  };

  const flowTodo = () => {
    const todoData = Object.values(todoList);
    if (!todoData.length) return;

    const selectedTodo = todoData[Math.floor(Math.random() * todoData.length)];
    setRando(selectedTodo.title);

    const updatedTodos = todoData.filter(item => item.id !== selectedTodo.id);
    setTodoList(updatedTodos.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}));

    setTimeout(() => setShowTime(true), 2000);
  };

  const refreshPage = () => {
    setClock(false);
    setShowTime(false);
    setRando("");
    setMyPick(25);

    const user = auth.currentUser;
    if (user) {
      const uid = user.uid;

      const projectsRef = ref(db, `users/${uid}/Projects/bubba`);

      onValue(projectsRef, (snapshot) => {
        if (snapshot.exists() && user) {
          setTodoList({
            ...snapshot.val(),
          });
        }
      });
    } else {
      console.log('No authenticated user');
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      {/* Header */}
      <InputContainer>
        <h1 style={{ color: "black", marginBottom: '30px' }}>{rando}</h1>
      </InputContainer>

      {/* Time Elements */}
      {clock && <Countdown until={mypick} />}
      {showTime && <TimerUp />}

      {/* Buttons Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mb-6">
        <button
          onClick={randomTodo}
          className="btn btn-primary rounded-lg p-4 h-100 shadow-lg text-white hover:bg-blue-700"
        >
          RANDOM
        </button>

        <button
          onClick={startTodo}
          className="btn btn-success rounded-lg p-4 h-100 shadow-lg text-white hover:bg-green-700"
        >
          START
        </button>

        <button
          onClick={flowTodo}
          className="btn btn-warning rounded-lg p-4 h-100 shadow-lg text-white hover:bg-yellow-700"
        >
          FLOW
        </button>

        <button
          onClick={refreshPage}
          className="btn btn-info rounded-lg p-4 h-100 shadow-lg text-white hover:bg-blue-600"
        >
          REFRESH
        </button>
      </div>

      {/* Todo List Items */}
      {Object.keys(todoList).map((key) => (
        <div key={key} className="mb-4 border rounded-lg p-4 shadow-sm">
          <h3>{todoList[key].title}</h3>
          
          <DeleteButton onClick={() => deleteTodo(key)}>
            <DeleteIcon style={{ color: '#ff4444' }} />
          </DeleteButton>
        </div>
      ))}
      
      {/* Form */}
      <Form />
    </div>
  );
};

export { TodoList };