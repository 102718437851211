// Import necessary Firebase modules
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Firestore reference
import { getDatabase, ref , onValue, child, set, remove } from "firebase/database";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail, updateEmail, updatePassword } from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyAwLjlTUPtHpzlaucCEJzZLKYJK54ZzQ3s",
  authDomain: "test-app-a3821.firebaseapp.com",
  databaseURL: "https://test-app-a3821-default-rtdb.firebaseio.com",
  projectId: "test-app-a3821",
  storageBucket: "test-app-a3821.appspot.com",
  messagingSenderId: "587533423775",
  appId: "1:587533423775:web:0a06a09e8590e4cdb2beaa",
  measurementId: "G-NLLC8RS3YE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const auth = getAuth(app);
export const dbFirestore = getFirestore(app); // Firestore reference
export const db = getDatabase(app); // Real-time Database
// Real-time Database reference
// Google sign-in configuration
const googleProvider = new GoogleAuthProvider();
googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    console.log(result.user);
  } catch (error) {
    console.error("Google sign-in error:", error.message);
  }
};
export { onAuthStateChanged };

export { onValue, child, set, remove , ref};
// Export Firebase app and services
export default { app, auth, dbFirestore, db, signInWithGoogle};
export { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail, updateEmail, updatePassword };