import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { ref, push, set } from 'firebase/database';
import { db } from '../../util/firebase';

const Form = ({ onSubmit }) => {
  const [title, setTitle] = useState('');

  const createTodo = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user && title.trim()) {
      try {
        // Push the new task to the correct Firebase path
        const todoListRef = ref(db, `users/${user.uid}/todoList`);
        const newTodoRef = push(todoListRef);
        await set(newTodoRef, { title });

        // Clear the input field
        setTitle('');

        // Call the parent component's handler
        onSubmit && await onSubmit(title);
      } catch (error) {
        console.error("Error adding todo:", error);
      }
    }
  };

  return (
    <div>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Add a new task"
      />
      <button onClick={createTodo}>Submit</button>
    </div>
  );
};

export default Form;