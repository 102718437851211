import React from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import Countdown from "../CountDown";
import TimerUp from "../TimerUP";

const TodoList = () => {
  const [rando, setRando] = React.useState("");
  const [clock, setClock] = React.useState(false);
  const [showTime, setShowTime] = React.useState(false);
  const [mypick, setMyPick] = React.useState(25);
  const [todoList, setTodoList] = React.useState([
    { id: "1", title: "Exercise" },
    { id: "2", title: "Read" },
    { id: "3", title: "Do Laundry" },
    { id: "4", title: "Go for walk" },
    { id: "5", title: "Study" }
  ]);
  const [newTodoItem, setNewTodoItem] = React.useState("");

  const deleteTodo = (id) => {
    setTodoList(todoList.filter((item) => item.id !== id));
  };

  const submitHandler = () => {
    if (newTodoItem.trim()) {
      const newItemId = `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
      setTodoList([...todoList, { title: newTodoItem, id: newItemId }]);
      setNewTodoItem("");
    }
  };

  const handleAddItem = (e) => {
    e.preventDefault();
    submitHandler();
  };

  const randomTodo = () => {
    const torandom = todoList.map((item, i) => ({ i, ...item }));
    var random = torandom[Math.floor(Math.random() * torandom.length)].title;
    setRando(random);
    setTodoList((prevTodos) =>
      prevTodos.filter((todo) => todo.title !== random)
    );

    setTimeout(() => {
      setClock(true);
    }, 2000);

    let maximum = 25;
    let minimum = 45;
    var randomnumber =
      Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
    setMyPick(randomnumber);
  };

  const startTodo = () => {
    const torandom = todoList.map((item, i) => ({ i, ...item }));
    var random = torandom[Math.floor(Math.random() * torandom.length)].title;
    setRando(random);
    setTodoList((prevTodos) =>
      prevTodos.filter((todo) => todo.title !== random)
    );

    setTimeout(() => {
      setClock(true);
    }, 2000);
  };

  const flowTodo = () => {
    const torandom = todoList.map((item, i) => ({ i, ...item }));
    var random = torandom[Math.floor(Math.random() * torandom.length)].title;
    setRando(random);
    setTodoList((prevTodos) =>
      prevTodos.filter((todo) => todo.title !== random)
    );

    setTimeout(() => {
      setShowTime(true);
    }, 2000);
  };

  return (
    <>
      <button
        onClick={randomTodo}
        className="btn btn-primary rounded-lg p-4 h-100 shadow-lg text-white hover:bg-blue-700"
      >
        RANDOM
      </button>
      <button
        onClick={() => startTodo(25)}
        className="btn btn-success rounded-lg p-4 h-100 shadow-lg text-white hover:bg-green-700"
      >
        START
      </button>
      <button
        onClick={flowTodo}
        className="btn btn-warning rounded-lg p-4 h-100 shadow-lg text-white hover:bg-yellow-700"
      >
        FLOW
      </button>
      <button
        onClick={() => window.location.reload()}
        className="btn btn-info rounded-lg p-4 h-100 shadow-lg text-white hover:bg-blue-600"
      >
        REFRESH
      </button>


      <div className="p-10">
        {/* Add the input field and form here */}
        <h3 className="text-pink-600 mb-6">{rando}</h3>

        {/* Time Elements */}
        {clock && <Countdown until={mypick} />}
        {showTime && <TimerUp />}

        {/* Todo List Items */}
        {todoList.map((item) => (
          <div key={item.id} className="mb-4 border rounded-lg p-4 shadow-sm flex justify-between items-center">
            <h3>{item.title}</h3>
            <button
              className="p-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full"
              onClick={() => deleteTodo(item.id)}
            >
              <DeleteForeverIcon style={{ color: "white" }} />
            </button>
          </div>
        ))}

        <form onSubmit={handleAddItem} className="flex mt-4">
          <input
            type="text"
            value={newTodoItem}
            onChange={(e) => setNewTodoItem(e.target.value)}
            placeholder="Enter a new todo item..."
            className="border border-gray-300 rounded-l-md px-4 py-2 flex-grow focus:outline-none"
          />
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r-md"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default TodoList;
