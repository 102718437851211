import React from "react";

import { Link } from "react-router-dom";
// import Button from "@material-ui/core/Button";

export const Footer = () => {
  return (
    <>
      <footer class="ud-pt-20 ud-pb-12">
        <div class="ud-container">
          <div class="ud-flex ud-flex-wrap ud-mx-[5px]">
            <div class="ud-w-full lg:ud-w-4/12 2xl:ud-w-5/12 ud-px-4">
              <div class="ud-mb-2">
                <p class="ud-text-base ud-text-body-color ud-font-medium">
                  © 2025 Rundoit
                </p>
              </div>
            </div>
            <div class="ud-w-full md:ud-w-8/12 lg:ud-w-5/12 2xl:ud-w-5/12 ud-px-4">
              <div class="ud-mb-1 ">
                <h3 class="ud-font-medium ud-text-black ud-text-xl ud-mb-2">
                  Quick Links
                </h3>
                <div class="ud-flex ud-flex-col ud-space-y-4">
                  <div class="ud-ml-0 ud-text-base ud-text-body-color ud-font-medium">
                    <Link
                      to="./general"
                      style={{ textDecoration: "none", hover: "textPrimary" }}
                    >
                      Home
                    </Link>
                  </div>
                  <div class="ud-ml-0 ud-text-base ud-text-body-color ud-font-medium">
                    <Link
                      to="./contact"
                      style={{ textDecoration: "none", hover: "textPrimary" }}
                    >
                      Contact
                    </Link>
                  </div>
                  <div class="twitter-icon-container">
  <a href="https://twitter.com/Rundoit" target="_blank" rel="noopener noreferrer">
    <span class="twitter-icon"></span>
   
  </a>
</div>

                  <div class="ud-ml-0 ud-text-base ud-text-body-color ud-font-medium">
                    <Link
                      to="./terms"
                      style={{ textDecoration: "none", hover: "textPrimary" }}
                    >
                      Terms
                    </Link>
                  </div>
                  <div class="ud-ml-0 ud-text-base ud-text-body-color ud-font-medium">
                    <Link
                      to="./privacy"
                      style={{ textDecoration: "none", hover: "textPrimary" }}
                    >
                      Privacy
                    </Link>
                  </div>
                  <div class="ud-ml-0 ud-text-base ud-text-body-color ud-font-medium">
                    <Link
                      to="./privacy"
                      style={{ textDecoration: "none", hover: "textPrimary" }}
                    ></Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="ud-w-full md:ud-w-4/12 lg:ud-w-3/12 2xl:ud-w-2/12 ud-px-4"></div>
          </div>
        </div>
      </footer>
    </>
  );
};
