import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Timer = styled.div`
  width: 100%;
  height: 100%;
  background-color: aliceblue;
  
  font-weight: bolder;
  border: 2px solid yellow;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  

`;

const Countdown = ({ until }) => {
    const [countDown, setCountDown] = useState(0);
    const [runTimer, setRunTimer] = useState(true);
    
    useEffect(() => {
      let timerId;
      console.log(until)
  
      if (runTimer) {
        setCountDown(60 * until);
        timerId = setInterval(() => {
          setCountDown((countDown) => countDown - 1);
        }, 1000);
      } else {
        clearInterval(timerId);
      }
  
      return () => clearInterval(timerId);
    }, [runTimer]);
  
    useEffect(() => {
      if (countDown < 0 && runTimer) {
        console.log("expired");
        alert("Congrats on finishing task")
        setRunTimer(false);
        setCountDown(0);
      }
    }, [countDown, runTimer]);
  
  //  const togglerTimer = () => setRunTimer((t) => !t);
  
    const seconds = String(countDown % 60).padStart(2, 0);
    const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);
   
    
  
  
    return (
      <div className="App">
        <Timer>

        
   
  
        
      <div>
   
           <span className='watch'>{minutes}</span>
          <h4>minutes</h4>
      </div>
       <div>
          <span className='watch'>{seconds}</span> 
          <h4>seconds</h4>
       </div>
         
        </Timer>
  
        {/* <button type="button" onClick={togglerTimer}>
          {runTimer ? "Stop" : "Start"}
        </button> */}
      </div>
    );
  }
  export default Countdown;